/* eslint-disable no-mixed-operators */
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
// import Stats from 'three/examples/jsm/libs/stats.module';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader';
import { KTXLoader } from 'three/examples/jsm/loaders/KTXLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { DirectionalLight, HemisphereLight } from 'three';
import ee from 'eventemitter2';
import observeResize from './resize-observer';
import { isDDS, isHDR, isJPG, isKTX } from './texture-type';
import { LoadTextureCorrected } from './load-texture-correct';
import { LoadingManager } from './loading-manager';
import Character from './character';
import IDBService, { GLBFile } from './indexdb';
import { useFrame } from '@react-three/fiber';

export interface ExhibitionHallConfiguration {
  helper?: boolean;
  lights?: boolean;
  loadingCover?: boolean;
  fps?: 25 | 30 | 60;
  orbitControls?:
    | boolean
    | {
        enablePan?: boolean;
        enableZoom?: boolean;
        enableRotateX?: boolean;
        enableRotateY?: boolean;
        enableRotate?: boolean;
      };
  loaders?: {
    gltf?:
      | {
          dracoLoaderPath?: string;
        }
      | boolean;
    fbx?: boolean;
    texture?: boolean;
    dds?: boolean;
    ktx?: boolean;
    cube?: boolean;
    hdr?: boolean;
  };
  camera?: {
    type?: 'perspective' | 'orthographic';
    fov?: number;
    near?: number;
    far?: number;
    position?: [number, number, number];
    focus?: [number, number, number];
    zoom?: number;
  };
}

const DEFAULT_CONFIGURATION = {
  helper: false,
  lights: true,
  loadingCover: true,
  orbitControls: true,
  fps: 25 as const,
  loaders: {
    gltf: { dracoLoaderPath: '/draco/gltf/' },
    fbx: false,
    texture: true,
    dds: false,
    ktx: false,
    cube: false,
    hdr: false,
  },
};

const uniforms = {
  time: { type: "f", value: 1.0 },
  resolution: { type: "v2", value: new THREE.Vector2() },
  //uColor: { value: new THREE.Color(0xffffff) },
  uTexture: { value: null }, // 初始化为null
  color: { value: null }, // 初始化为null
  //morphTargetInfluences: { value: new Float32Array(5) },
  // 添加骨骼矩阵
  // boneMatrices: { value: new THREE.Matrix4() } // 这里需要传递实际的骨骼矩阵
  //boneMatrices: { value: new Float32Array(59 * 16) }, // 59个骨骼，每个骨骼16个浮点数
};
window.uniforms = uniforms;

const vertexShaderTemplate = `
    varying vec2 vUv;
    varying vec3 Normal;
    varying vec3 Position;
    #include <common>
    #include <skinning_pars_vertex>
    #include <morphtarget_pars_vertex>
    
    void main() {
      vUv = uv;
      #include <skinbase_vertex>
      #include <begin_vertex>
      #include <beginnormal_vertex>
      #include <defaultnormal_vertex>
      #include <skinning_vertex>
      #include <morphtarget_vertex>
      #include <project_vertex>

      Normal = normalize(normalMatrix * normal);
      Position = vec3(modelViewMatrix * vec4(position, 1.0));
      gl_Position = projectionMatrix * mvPosition;
    }
  `;
const fragmentShaderTemplateWithMap = `
    precision lowp float;
    uniform vec3 uColor;
    varying vec2 vUv;
    uniform sampler2D uMetalnessMap;
    uniform sampler2D uNormalMap;
    uniform sampler2D uTexture; // 纹理采样器
    void main() {
      //仅纹理颜色
      vec4 texColor = texture2D(uTexture, vUv); // 使用传递的UV坐标
      gl_FragColor = texColor; // 输出纹理颜色
    }
  `;
const fragmentShaderTemplateWithoutMap = `
  precision lowp float;
  uniform vec3 uColor;
  varying vec2 vUv;
  uniform sampler2D uMetalnessMap;
  uniform sampler2D uNormalMap;
  uniform sampler2D uTexture; // 纹理采样器
  uniform float uOpacity; // 接收透明度的uniform
  uniform vec3 uEmissive;
  uniform float uRoughness;
  void main() {
    //无material.map颜色
    vec4 metalnessColor = texture2D(uMetalnessMap, vUv);
    vec3 diffuseColor = color * (1.0 - metalnessColor.r); // 基础颜色
    // 从法线贴图获取法线
    vec3 sampledNormal = texture2D(uNormalMap, vUv).rgb; 
    sampledNormal = normalize(sampledNormal * 2.0 - 1.0); // 将[0,1]映射到[-1,1]

    // 使用插值法线进行光照计算
    vec3 finalNormal = normalize(vNormal + sampledNormal); // 合成法线
    
    // Roughness处理
    float roughnessFactor = clamp(roughness, 0.04, 1.0); // 限制粗糙度范围
    
    // Emissive计算
    vec3 emissiveColor = emissive; 

    // 最终颜色计算
    vec3 finalColor = diffuseColor + emissiveColor; // 加入发光颜色
    gl_FragColor = vec4(finalColor, 1);
  }
`;
/**
 * 展厅基础类
 */
class ExhibitionHall extends ee {
  rootEle: HTMLElement;

  // 画布元素A
  canvas: HTMLCanvasElement;

  // 场景
  scene: THREE.Scene;

  // 时钟
  clock: THREE.Clock;

  // 透视相机
  camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;

  // 渲染器
  renderer: THREE.WebGLRenderer;

  axesHelper?: THREE.AxesHelper;

  orbitControls?: OrbitControls;

  renderFns: ((time?: number) => void)[] = [];

  loopRenderId?: number;

  lights: { [p: string]: THREE.Light } = {};

  meshs: { [p: string]: THREE.Mesh } = {};

  textures: { [p: string]: THREE.Texture } = {};

  gltfLoader?: GLTFLoader;

  fbxLoader?: FBXLoader;

  textureLoader?: THREE.TextureLoader;

  ddsLoader?: DDSLoader;

  ktxLoader?: KTXLoader;

  cubeLoader?: THREE.CubeTextureLoader;

  hdrLoader?: RGBELoader;

  mManager?: LoadingManager;

  configuration: ExhibitionHallConfiguration = { ...DEFAULT_CONFIGURATION };

  modelMap: Map<string, Character> = new Map();

  defaultModel?: Character;

  coverEle?: HTMLDivElement;

  disposeFns: (() => void)[] = [];
  fps: 25 | 30 | 60;
  singleFrameTime: number;

  addCharacter = (model: Character) => {
    if (this.modelMap.size === 0) {
      this.defaultModel = model;
      const position = model.center;
      this.camera.position.set(position.x + this.camera.position.x, position.y + + this.camera.position.y, position.z + this.camera.position.z);
      this.camera.lookAt(position);
      //this.orbitControls?.target.set(position.x, position.y, position.z);
      if (this.configuration.helper) {
        
      }
      /** */
      const newMaterial = new THREE.ShaderMaterial({
        uniforms: uniforms,
        skinning: true, // 启用骨骼动画
        morphTargets: true, // 启用变形动画
        vertexShader: vertexShaderTemplate,
        fragmentShader: fragmentShaderTemplateWithMap,
      });
      
      model.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          const originalMaterial = child.material;
          const newMaterialTem = new THREE.ShaderMaterial({
            //uniforms: uniforms,
            uniforms: {
              uTexture: { value: (originalMaterial as THREE.MeshStandardMaterial).map }, // 传递纹理
              uColor: { value: (originalMaterial as THREE.MeshStandardMaterial).color }, // 传递颜色
              uMetalness: { value: (originalMaterial as THREE.MeshStandardMaterial).metalness }, // 传递金属度
              uMetalnessmap: { value: (originalMaterial as THREE.MeshStandardMaterial).metalnessMap }, // 传递金属度贴图
              uNormalmap: { value: (originalMaterial as THREE.MeshStandardMaterial).normalMap }, // 传递法线贴图
              uRoughness: { value: (originalMaterial as THREE.MeshStandardMaterial).roughness }, // 传递粗糙度
              uOpacity: { value: (originalMaterial as THREE.MeshStandardMaterial).opacity }, // 传递透明度
              uEmissive: { value: (originalMaterial as THREE.MeshStandardMaterial).emissive }, // 传递高光颜色
            },
            skinning: true, // 启用骨骼动画
            morphTargets: true, // 启用变形动画
            vertexShader: vertexShaderTemplate,
            fragmentShader: fragmentShaderTemplateWithMap,
            transparent: true,
            depthWrite: true,
            blending: THREE.NormalBlending,
            colorWrite: true,
            name: (originalMaterial instanceof THREE.Material) ? originalMaterial.name : '',
            side: THREE.DoubleSide,
          });
          // 检查材质是否为数组，并处理每个材质
          if (Array.isArray(originalMaterial)) {
            originalMaterial.forEach((material) => {
              if (material instanceof THREE.MeshStandardMaterial && material.map) {
                newMaterialTem.uniforms.uTexture.value = material.map;
              }
              if (material instanceof THREE.MeshStandardMaterial && !material.map) {
                newMaterialTem.fragmentShader = fragmentShaderTemplateWithoutMap;
              }
            });
          } else if (originalMaterial instanceof THREE.MeshStandardMaterial) {
            if (originalMaterial.map) {
              newMaterialTem.uniforms.uTexture.value = originalMaterial.map;
            } else {
              newMaterialTem.fragmentShader = fragmentShaderTemplateWithoutMap;
            }
          }

          child.material = newMaterialTem;
        }
        //  else if (child.name.indexOf('hair_qian') != -1 && child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
        // // if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
        //   const newMaterialTem2 = new THREE.MeshStandardMaterial({
        //     color: child.material.color,
        //     opacity: child.material.opacity, 
        //     map: child.material.map, 
        //     lightMap: child.material.lightMap,
        //     lightMapIntensity: child.material.lightMapIntensity,
        //     aoMap: child.material.aoMap,
        //     aoMapIntensity: child.material.aoMapIntensity,
        //     emissive: child.material.emissive,
        //     emissiveIntensity: child.material.emissiveIntensity,
        //     emissiveMap: child.material.emissiveMap,
        //     bumpMap: child.material.bumpMap,
        //     bumpScale: child.material.bumpScale,
        //     normalMap: child.material.normalMap,
        //     normalMapType: child.material.normalMapType,
        //     normalScale: child.material.normalScale,
        //     displacementMap: child.material.displacementMap,
        //     displacementScale: child.material.displacementScale,
        //     displacementBias: child.material.displacementBias,
        //     alphaMap: child.material.alphaMap,
        //     envMap: child.material.envMap,
        //     //transparent: true,
        //     // depthWrite: true,
        //     // depthTest: true,
        //     // blending: THREE.NormalBlending,
        //     // side: THREE.DoubleSide,
        //   });
        //   child.material = newMaterialTem2;
        // }
      });
    }
    setTimeout(() => {
      this.scene.add(model.scene);
    }, 70);
    this.modelMap.set(model.name, model);
  };

  protected initCanvas() {
    this.emit('onBeforeInitCanvas');
    const parentEle = this.rootEle;

    // 防止屏幕抖动
    parentEle.style.overflow = 'hidden';
    parentEle.style.position = 'relative';
    this.rootEle.appendChild(this.canvas);
    const disposeObserver = observeResize(parentEle!, ({ width, height }) => {
      // @ts-ignore
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(width, height);
      this.renderer.setPixelRatio(window.devicePixelRatio);
    });
    this.disposeFns.push(disposeObserver);

    this.emit('onAfterInitCanvas');
  }

  protected initRenderer() {
    this.emit('onBeforeInitRenderer');
    this.renderer.setClearColor(0x000000, 0);
    //适配1
    // this.renderer.outputColorSpace = SRGBColorSpace;
    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    // 设置渲染器的阴影计算
    this.renderer.shadowMap.enabled = true;
    this.renderFns.push(() => {
      this.renderer.render(this.scene, this.camera);
    });
    this.emit('onAfterInitRenderer');
  }

  protected initCamera() {
    this.emit('onBeforeInitCamera');
    const config = this.configuration.camera;
    if (config?.type !== 'orthographic') {
      const camera = this.camera as THREE.PerspectiveCamera;
      if (config?.fov) {
        camera.fov = config?.fov;
      }
    }
    this.camera.near = config?.near ?? 0.01;

    if (config?.far) {
      this.camera.far = config?.far;
    }
    if (config?.zoom) {
      this.camera.zoom = config?.zoom;
    }
    // 设置相机
    this.camera.position.set(...(config?.position ?? [0, 0, 10]));
    if (config?.focus) {
      this.camera.lookAt(...config.focus);
    }
    this.emit('onAfterInitCamera');
  }

  protected initHelper() {
    if (!this.configuration.helper) return;
    this.emit('onBeforeInitHelper');
    // const axesHelper = new THREE.AxesHelper(5);
    // this.axesHelper = axesHelper;
    // const axesMaterial = axesHelper.material as THREE.Material;
    // axesMaterial.transparent = true;
    // axesMaterial.opacity = 0.75;
    // this.scene.add(axesHelper);

    // const gridHelper = new THREE.GridHelper(10, 10);
    // const gridMaterial = gridHelper.material as THREE.Material;
    // gridMaterial.transparent = true;
    // gridMaterial.opacity = 0.2;
    // this.scene.add(gridHelper);

    // const stats = new Stats();
    // stats.showPanel(0);
    // document.body.append(stats.dom);
    // this.renderFns.push(() => stats.update());
    this.emit('onAfterInitHelper');
  }

  protected initOrbitControls() {
    if (!this.configuration.orbitControls) return;
    this.emit('onBeforeInitOrbitControls');
    this.orbitControls = new OrbitControls(this.camera, this.canvas);

    if (typeof this.configuration.orbitControls === 'object') {
      const config = this.configuration.orbitControls;
      if (config.enableZoom !== undefined) {
        this.orbitControls.enableZoom = config.enableZoom;
      }
      if (config.enablePan !== undefined) {
        this.orbitControls.enablePan = config.enablePan;
      }
      this.orbitControls.enableRotate = config.enableRotate ?? false;
      if (config.enableRotateX || config.enableRotateY) {
        let isDragging = false;
        const handleRotateStart = () => {
          isDragging = true;
        };
        const handleRotateEnd = () => {
          isDragging = false;
        };
        const handleRotating = (e: MouseEvent) => {
          if (!isDragging || (e.buttons != null && e.buttons !== 1)) {
            return;
          }
          const moveDegX = 0.01 * e.movementX;
          const moveDegY = 0.01 * e.movementY;
          if (this.defaultModel) {
            if (config.enableRotateX) {
              this.defaultModel.scene.rotation.y += moveDegX;
            }
            if (config.enableRotateY) {
              this.defaultModel.scene.rotation.x += moveDegY;
            }
          }
        };
        this.renderer.domElement.addEventListener(
          'mousedown',
          handleRotateStart
        );
        this.renderer.domElement.addEventListener('mousemove', handleRotating);
        this.renderer.domElement.addEventListener('mouseup', handleRotateEnd);
      }
    }
    if (this.configuration.camera?.focus) {
      this.orbitControls.target.set(...this.configuration.camera!.focus!);
    }
    this.renderFns.unshift(() => this.orbitControls!.update());
    // 更新相机矩阵
    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld();
    this.emit('onAfterInitOrbitControls');
  }

  protected initLights() {
    if (!this.configuration.lights) return;
    this.emit('onBeforeInitLights');
    // const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
    // this.scene.add(ambientLight)
    // this.lights.ambientLight = ambientLight
    const hemisphereLight = new HemisphereLight(0xffffff);
    hemisphereLight.position.set(0, 20, 0);
    // hemisphereLight.intensity = 0.5
    this.scene.add(hemisphereLight);
    const directionalLight = new DirectionalLight(0xffffff);
    directionalLight.position.set(3, 10, 10);
    directionalLight.castShadow = true;
    directionalLight.shadow.camera.top = 2;
    directionalLight.shadow.camera.bottom = -2;
    directionalLight.shadow.camera.left = -2;
    directionalLight.shadow.camera.right = 2;
    directionalLight.shadow.camera.near = 0.1;
    directionalLight.shadow.camera.far = 40;
    // directionalLight.intensity = 0.8
    this.scene.add(directionalLight);

    // this.addLight('light1', new THREE.DirectionalLight(0xffffff, 0.2)).position.set(0, 0, 10)
    // this.addLight('light2', new THREE.DirectionalLight(0xffffff, 0.2)).position.set(0, 0, -10)
    // this.addLight('light3', new THREE.DirectionalLight(0xffffff, 0.2)).position.set(10, 0, 0)
    // this.addLight('light4', new THREE.DirectionalLight(0xffffff, 0.2)).position.set(-10, 0, 0)
    // this.addLight('light5', new THREE.DirectionalLight(0xffffff, 0.2)).position.set(0, 10, 0)
    // this.addLight('light6', new THREE.DirectionalLight(0xffffff, 0.2)).position.set(5, 10, 0)
    // this.addLight('light7', new THREE.DirectionalLight(0xffffff, 0.2)).position.set(0, 10, 5)
    // this.addLight('light8', new THREE.DirectionalLight(0xffffff, 0.2)).position.set(0, 10, -5)
    // this.addLight('light9', new THREE.DirectionalLight(0xffffff, 0.2)).position.set(-5, 10, 0)
    this.emit('onAfterInitLights');
  }

  protected initLoadingManager() {
    this.mManager = new LoadingManager({
      handleProcess: (process: number) => {
        this.showCover(process);
        this.emit('onLoadProcess', process);
      },
      handleSuccess: () => {
        this.hideCover();
        this.emit('onLoadSuccess');
      },
      handleFailed: () => {
        this.hideCover();
        this.emit('onLoadFailed');
      },
    });
  }

  protected initLoaders() {
    if (!this.configuration.loaders) return;
    this.emit('onBeforeInitLoaders');
    const { gltf, fbx, dds, ktx, texture, cube, hdr } =
      this.configuration.loaders;
    if (gltf) {
      const loader = new GLTFLoader();
      // 提供解压缩的文件
      if (typeof gltf === 'object' && gltf.dracoLoaderPath) {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath(gltf.dracoLoaderPath ?? '/draco/gltf/');
        dracoLoader.setDecoderConfig({ type: 'js' });
        loader.setDRACOLoader(dracoLoader);
      }
      this.gltfLoader = loader;
    }
    if (fbx) {
      const loader = new FBXLoader();
      this.fbxLoader = loader;
    }
    if (texture) {
      const loader = new THREE.TextureLoader();
      this.textureLoader = loader;
    }
    if (dds) {
      const loader = new DDSLoader();
      this.ddsLoader = loader;
    }
    if (ktx) {
      const loader = new KTXLoader();
      this.ktxLoader = loader;
    }
    if (cube) {
      const loader = new THREE.CubeTextureLoader();
      this.cubeLoader = loader;
    }
    if (hdr) {
      const loader = new RGBELoader();
      this.hdrLoader = loader;
    }
    this.emit('onAfterInitLoaders');
  }

  protected initCover() {
    if (!this.configuration.loadingCover) return;
    const coverEle = (this.coverEle = document.createElement('div'));
    Object.assign(coverEle.style, {
      position: 'absolute',
      display: 'none',
      'flex-direction': 'column',
      'justify-content': 'center',
      'align-items': 'center',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      'z-index': 999,
      // 'background-color': '#CCC',
      'user-select': 'none',
      color: '#fff',
      'font-size': '36px',
      'text-align': 'center',
    });
    this.rootEle.appendChild(this.coverEle);
  }

  constructor(
    rootEle: HTMLElement,
    configuration: ExhibitionHallConfiguration = {}
  ) {
    super();
    this.rootEle = rootEle;

    Object.assign(this.configuration, configuration);
    this.fps = this.configuration.fps ?? 25;
    this.singleFrameTime = 1000 / this.fps;
    this.clock = new THREE.Clock();
    this.scene = new THREE.Scene();
    if (this.configuration.camera?.type === 'orthographic') {
      this.camera = new THREE.OrthographicCamera(0, 0, 0, 0);
    } else {
      this.camera = new THREE.PerspectiveCamera();
    }
    this.renderer = new THREE.WebGLRenderer({
      // 抗锯齿
      antialias: true,
      alpha: true,
    });
    this.canvas = this.renderer.domElement;
    this.initCover();
    this.initLoadingManager();
    this.initLoaders();
    this.initHelper();
    this.initCanvas();
    this.initOrbitControls();
    this.initCamera();
    // this.initLights();
    this.initRenderer();
  }

  addLight = (name: string, light: THREE.Light) => {
    this.lights[name] = light;
    this.scene.add(light);
    return light;
  };

  removeLight = (...names: string[]) => {
    for (const name of names) {
      this.scene.remove(this.lights[name]);
    }
  };

  loadGLTF = async (path: string, name?: string): Promise<GLTF> => {
    name = name || path.split('/').slice(-1)[0];
    // add gzip header
    //this.gltfLoader?.setRequestHeader({ 'Content-Encoding': 'gzip' });
    const idbService = new IDBService('glbCacheDB', 'glbCacheStore');
    const cachedData = await idbService.getGLBFile(path)
    // if (cachedData) {
    //if (false) {
      // const arrayBuffer = cachedData;
      // const blob = new Blob([arrayBuffer], { type: 'model/gltf-binary' });
      // const objectURL = URL.createObjectURL(blob);
      // return new Promise((resolve, reject) => {
      //   this.gltfLoader?.load(
      //   objectURL,
      //   (gltf) => {
      //     resolve(gltf);
      //     console.log('resolve after time:', performance.now() - window.startTime);
      //   },
      //   (pe) => {
      //     this.mManager?.set(path, pe.loaded, pe.total);
      //     this.mManager?.handleProcess();
      //     this.emit(['progress', name!], pe.loaded, pe.total);
      //   },
      //   (e) => {
      //     this.mManager?.setFailed();
      //     alert('Failed to load GLTF file');
      //     reject(e);
      //   })
      //   console.log('cachedData load time:', performance.now() - window.startTime);
      // });
    //} else {
      this.mManager?.register(path);
      const response = await fetch(path);
      const data = await response.arrayBuffer();
      const glbFile: GLBFile = {
        url: path,
        data: data,
      };
      idbService.addGLBFile(glbFile).then(() => {
        console.log('cache success');
      }).catch((error: any) => {
        console.error('Failed to cache GLB file:', error);
      });
      return new Promise((resolve, reject) => {
        this.gltfLoader?.parse(
          data,
          '',
          (gltf) => {
            resolve(gltf);
          },
          (e) => {
            this.mManager?.setFailed();
            alert('Failed to load GLTF file');
            reject(e);
          }
        );
        console.log('nocachedData load time:', performance.now() - window.startTime);
      });
    //}
  };

  loadFBX = (path: string, name?: string): Promise<THREE.Group> => {
    name = name || path.split('/').slice(-1)[0];
    return new Promise((resolve, reject) => {
      this.mManager?.register(path);
      this.fbxLoader?.load(
        path,
        (group) => {
          resolve(group);
        },
        (pe) => {
          this.mManager?.set(path, pe.loaded, pe.total);
          this.mManager?.handleProcess();
          this.emit(['progress', name!], pe.loaded, pe.total);
        },
        (e) => {
          this.mManager?.setFailed();
          reject(e);
        }
      );
    });
  };

  loadTexture = (path: string, basePath = '') => {
    if (basePath) {
      path = basePath + path;
    }
    // if (isDDS(path)) {
    //   if (!this.ddsLoader) {
    //     throw new Error('missing ddsLoader!');
    //   }
    //   return LoadTextureCorrected(
    //     this.ddsLoader! as THREE.TextureLoader,
    //     path,
    //     this.mManager!
    //   );
    // }
    // if (isKTX(path)) {
    //   if (!this.ktxLoader) {
    //     throw new Error('missing ddsLoader!');
    //   }
    //   return LoadTextureCorrected(
    //     this.ktxLoader! as THREE.TextureLoader,
    //     path,
    //     this.mManager!
    //   );
    // }
    if (isJPG(path)) {
      if (!this.textureLoader) {
        throw new Error('missing textureLoader!');
      }
      return LoadTextureCorrected(this.textureLoader!, path, this.mManager!);
    }
    // if (isHDR(path)) {
    //   if (!this.hdrLoader) {
    //     throw new Error('missing hdrLoader!');
    //   }
    //   return LoadTextureCorrected(this.hdrLoader!, path, this.mManager!);
    // }
    throw new Error('loader not found');
  };

  loadEnvTexture = (paths: string[], basePath = '') => {
    if (basePath) {
      paths = paths.map((p) => basePath + p);
    }
    if (!this.cubeLoader) {
      throw new Error('missing cubeLoader!');
    }
    const cubeTexture = this.cubeLoader.load(
      paths,
      () => {},
      (pe) => {
        paths.forEach((_path) => {
          this.mManager!.set(_path, pe.loaded, pe.total);
        });
        this.mManager!.handleProcess();
      },
      () => {
        this.mManager!.setFailed();
      }
    );
    cubeTexture.format = THREE.RGBAFormat;
    cubeTexture.mapping = THREE.CubeReflectionMapping;
    return cubeTexture;
  };
  timeStamp = 0;
  renderLoop: (time?: number) => void = (time = performance.now()) => {
    this.loopRenderId = requestAnimationFrame(this.renderLoop);
    const delta = this.clock.getDelta();
    this.timeStamp += delta * 1000;

    if (this.timeStamp >= this.singleFrameTime) {
      this.timeStamp = this.timeStamp % this.singleFrameTime;
      for (const fn of this.renderFns) {
        fn.call(this, time!);
      }
    }
  };

  shaderanimate = () => {
    requestAnimationFrame(this.shaderanimate);
    const delta = this.clock.getDelta();
		uniforms.time.value += delta * 5;
    if (this.defaultModel?.mixer) {
      this.defaultModel.mixer.update(delta);
    }
    this.renderer.render(this.scene, this.camera);
  }

  cancelRender: () => void = () => {
    if (this.loopRenderId) cancelAnimationFrame(this.loopRenderId);
  };

  addRenderFn: (fn: (time?: number) => void) => void = (fn) => {
    this.renderFns.push(fn);
  };

  removeRenderFn: (fn: (time?: number) => void) => void = (fn) => {
    const index = this.renderFns.indexOf(fn);
    if (index > -1) {
      this.renderFns.splice(index, 1);
    }
  };

  showCover = (process?: number, text?: string) => {
    if (!this.coverEle) return;
    this.coverEle.innerHTML = `
      <div>${text ?? ''}</div>
      <div style='width: 130px; height:130px; line-height: 130px; border-radius: 50%; background: rgba(0,0,0, 0.4)'>${
        process ?? 0
      }%</div>
    `;
    this.coverEle.style.display = 'flex';
  };

  hideCover = () => {
    setTimeout(() => {
      if (!this.coverEle) return;
      this.coverEle.style.display = 'none';
    }, 1000);
  };

  dispose = () => {
    this.disposeFns.forEach((fn) => fn());
    this.cancelRender();
    this.orbitControls?.dispose();
    this.renderer.dispose();
    this.camera = null as any;
    this.renderer = null as any;
    this.scene = null as any;
    this.orbitControls = null as any;
    this.canvas?.remove();
    this.canvas = null as any;
  };
}

export default ExhibitionHall;
