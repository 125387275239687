/* eslint-disable no-underscore-dangle */
export interface LoadingManagerOptions {
  handleProcess?: (process: number) => void;
  handleSuccess?: () => void;
  handleFailed?: () => void;
}
export class LoadingManager {
  private _loadingMap: {
    [loadingKey: string]: {
      loaded: number;
      total: number;
    };
  };

  private _loadingKey: string[];

  private _isStartProcess: boolean;

  private _isFailed: boolean;

  private _handleProcess: LoadingManagerOptions['handleProcess'];

  private _handleSuccess: LoadingManagerOptions['handleSuccess'];

  private _handleFailed: LoadingManagerOptions['handleSuccess'];

  constructor(options?: LoadingManagerOptions) {
    this._loadingMap = {};
    this._loadingKey = [];
    this._isStartProcess = false;
    this._isFailed = false;
    this._handleProcess = options?.handleProcess;
    this._handleSuccess = options?.handleSuccess;
    this._handleFailed = options?.handleFailed;
  }

  register(key: string) {
    if (this._loadingKey.includes(key)) {
      return;
    }
    this._loadingKey.push(key);
  }

  set(key: string, loaded: number, total: number) {
    this._loadingMap[key] = {
      loaded,
      total,
    };
    if (this._isStartProcess) {
      return;
    }
    if (
      this._loadingKey.every(
        (loadingKey) => this._loadingMap[loadingKey] != null,
      )
    ) {
      this._isStartProcess = true;
    }
  }

  remove(key: string) {
    delete this._loadingMap[key];
  }

  clear() {
    this._loadingMap = {};
  }

  setFailed() {
    this._isFailed = true;
    this._handleFailed?.();
  }

  getProcess() {
    const loadingMaps = Object.values(this._loadingMap);
    let loaded = 0;
    let total = 0;
    loadingMaps.forEach((loadingMap) => {
      loaded += loadingMap.loaded;
      total += loadingMap.total;
    });
    return { loaded, total };
  }

  handleProcess() {
    if (this._loadingKey.length < 1 || this._isFailed) {
      return;
    }
    if (!this._isStartProcess) {
      this._handleProcess?.(0);
      return;
    }
    const { loaded, total } = this.getProcess();
    let process = 0;
    if (total > 0) {
      process = Math.round((loaded / total) * 100);
    }
    this._handleProcess?.(process);
    if (loaded === total) {
      this._handleSuccess?.();
    }
  }
}
