export const SINGLE_FRAME_TIME = 1000 / 60;

// 共61个
export const AZURE_TTS_BLEND_SHAPES: string[] = [
  'eyeBlinkLeft',
  'eyeLookDownLeft',
  'eyeLookInLeft',
  'eyeLookOutLeft',
  'eyeLookUpLeft',
  'eyeSquintLeft',
  'eyeWideLeft',
  'eyeBlinkRight',
  'eyeLookDownRight',
  'eyeLookInRight',
  'eyeLookOutRight',
  'eyeLookUpRight',
  'eyeSquintRight',
  'eyeWideRight',
  'jawForward',
  'jawRight',
  'jawLeft',
  'jawOpen',
  'mouthClose',
  'mouthFunnel',
  'mouthPucker',
  'mouthRight',
  'mouthLeft',
  'mouthSmileLeft',
  'mouthSmileRight',
  'mouthFrownLeft',
  'mouthFrownRight',
  'mouthDimpleLeft',
  'mouthDimpleRight',
  'mouthStretchLeft',
  'mouthStretchRight',
  'mouthRollLower',
  'mouthRollUpper',
  'mouthShrugLower',
  'mouthShrugUpper',
  'mouthPressLeft',
  'mouthPressRight',
  'mouthLowerDownLeft',
  'mouthLowerDownRight',
  'mouthUpperUpLeft',
  'mouthUpperUpRight',
  'browDownLeft',
  'browDownRight',
  'browInnerUp',
  'browOuterUpLeft',
  'browOuterUpRight',
  'cheekPuff',
  'cheekSquintLeft',
  'cheekSquintRight',
  'noseSneerLeft',
  'noseSneerRight',
  'tongueOut',
  'headYaw',
  'headPitch',
  'headRoll',
  'leftEyeYaw',
  'leftEyePitch',
  'leftEyeRoll',
  'rightEyeYaw',
  'rightEyePitch',
  'rightEyeRoll',
];

export const BLINK_VALUE = [
  0,
  0,
  0.003,
  0.022,
  0.063,
  0.149,
  0.266,
  0.389,
  0.6,
  0.389,
  0.266,
  0.149,
  0.063,
  0.022,
  0.003,
  0,
  0,
].map((x) => x * 1.66);

// 眨眼最小/大的间隔
export const MIN_BLINK_INTERVAL = 30 * 3;
export const MAX_BLINK_INTERVAL = 90 * 2;
export const LOOP_BLINK_TIME = 3;
