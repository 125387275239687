/* eslint-disable no-underscore-dangle */
/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-loop-func */
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import gsap from 'gsap';
import { Howl } from 'howler';
import ee from 'eventemitter2';
import ExhibitionHall from './exhibition-hall';
import { findMaterialTarget, findMorphTarget, randIntChoice } from './utils';
import {
  AZURE_TTS_BLEND_SHAPES,
  BLINK_VALUE,
  LOOP_BLINK_TIME,
  MAX_BLINK_INTERVAL,
  MIN_BLINK_INTERVAL,
} from './const';
import Action, { ActionConfig } from './action';

export interface CharacterOptions {
  blinkMorphNames?: string[];
  idleAction?: string;
  idleLoopActions?: string[];
  talkingidleLoopActions?: string[];
  interactngidleLoopActions?: string[];
  talkMorphNames?: string[];
  talkMorphTargetNames?: string[];
  blinkMorphTargetNames?: string[];
  position?: [number, number, number];
  scale?: [number, number, number];
}
class Character extends ee {
  scene: THREE.Scene;

  name: string;

  actionMap: Map<string, Action> = new Map();

  mixer: THREE.AnimationMixer | null = null;

  hall: ExhibitionHall;

  loop = false;

  morphTargets: THREE.Mesh[] = [];

  defaultMorphDict: Map<THREE.Mesh, number[]> = new Map<THREE.Mesh, number[]>();

  playTalkId: string | null = null;

  talkVolume = 1;

  isTalking = false;

  toPlayTalkMorphNames: { [key: string]: number } = {};

  options: CharacterOptions;

  talkMorphTargetNames?: string[];

  blinkMorphTargetNames?: string[];

  currentAction?: string;

  idle?: string;

  //点击互动按钮之后就算开始交互。处于播报状态算Talking
  isInteracting = false;

  idleLoopActions?: string[];
  
  //互动状态下的循环动作
  interactngidleLoopActions?: string[];

  //讲话状态下的循环动作
  talkingidleLoopActions?: string[];

  //用于修改已有贴图的属性
  materialTargets: THREE.Mesh[] = [];
  //用于角色默认居中
  center = new THREE.Vector3();

  gltfanimations?: THREE.AnimationClip[] = [];

  clock = new THREE.Clock();

  constructor(
    name: string,
    gltf: GLTF,
    hall: ExhibitionHall,
    options?: CharacterOptions
  ) {
    super();
    this.name = name;
    this.scene = gltf.scene;
    this.hall = hall;
    this.options = options || {};
    this.idle = options?.idleAction;
    this.idleLoopActions = options?.idleLoopActions;
    this.talkingidleLoopActions = (options?.talkingidleLoopActions && options?.talkingidleLoopActions.length >0)? options?.talkingidleLoopActions : options?.idleLoopActions;
    this.interactngidleLoopActions = (options?.interactngidleLoopActions && options?.interactngidleLoopActions.length >0)? options?.interactngidleLoopActions : options?.idleLoopActions;
    console.log('interactngidleLoopActions load time:', performance.now() - window.startTime);
    this.mixAnimation(gltf.animations);
    console.log('mixAnimation load time:', performance.now() - window.startTime);
    this.gltfanimations = gltf.animations;
    console.log('gltfanimations load time:', performance.now() - window.startTime);
    this.morphTargets = findMorphTarget(gltf.scene);
    this.materialTargets = findMaterialTarget(gltf.scene);
    this.proxyMorphTarget();
    console.log('materialTargets load time:', performance.now() - window.startTime);
    console.log('morphTargets ===>', this.morphTargets);
    console.log('materialTargets ===>', this.materialTargets);
    this.resolveDefaultMorph();
    if (options?.talkMorphNames) {
      this.resolveTalkMorphNames(options.talkMorphNames);
    } else {
      this.resolveTalkMorphNames(AZURE_TTS_BLEND_SHAPES);
    }
    console.log('resolveTalkMorphNames load time:', performance.now() - window.startTime);

    if (options) {
      if (options.position) {
        this.scene.position.set(...options.position);
      }
      if (options.scale) {
        this.scene.scale.set(...options.scale);
      }

      if (options.blinkMorphNames) {
        this.blinkEyes(options.blinkMorphNames);
      }
      if (options.talkMorphTargetNames) {
        this.talkMorphTargetNames = options.talkMorphTargetNames;
      }
      if (options.blinkMorphTargetNames) {
        this.blinkMorphTargetNames = options.blinkMorphTargetNames;
      }
      if (options.idleAction) {
        const idleAction = this.actionMap.get(options.idleAction);
        // idleAction?.setConfig({
        //   loop: THREE.LoopRepeat,
        //   repetitions: Infinity,
        // })
        idleAction?.play();
      }
      console.log('options load time:', performance.now() - window.startTime);
    }

    // this.materialTargets.forEach((mesh) => {
    //   if((mesh.material as THREE.MeshPhysicalMaterial).name =="Jett_Wing_Light_SH.002"){
    //     (mesh.material as THREE.MeshPhysicalMaterial).emissive.r = 0.8;
    //     console.log((mesh.material as THREE.MeshPhysicalMaterial).emissive);
    //   }
    // });
    hall.addCharacter(this);
    console.log('hall.addCharacter load time:', performance.now() - window.startTime);
  }

  private proxyMorphTarget = () => {
    for (const mesh of this.morphTargets) {
      // @ts-ignore
      Object.defineProperty(mesh, '__morphProxy__', {
        get() {
          return Object.entries(mesh.morphTargetDictionary!).reduce(
            (prev: any, [name, index]) => {
              prev[name] = mesh.morphTargetInfluences![index];
              return prev;
            },
            {}
          );
        },
        set(obj: object) {
          Object.entries(obj).forEach(([name, value]) => {
            const index = mesh.morphTargetDictionary![name];
            if (index !== undefined && value !== undefined) {
              mesh.morphTargetInfluences![index] = value;
            }
          });
        },
      });
    }
  };

  private resolveDefaultMorph() {
    this.morphTargets.forEach((mesh) => {
      this.defaultMorphDict.set(
        mesh,
        mesh.morphTargetInfluences
          ? Array.from(mesh.morphTargetInfluences!)
          : new Array<number>(
              Object.keys(mesh.morphTargetDictionary!).length
            ).fill(0)
      );
    });
  }

  resetMorphSmooth = (duration = 1, morphTargetName?: string) =>
    new Promise((resolve, reject) => {
      let finishCount = 0;
      let found = false;
      for (const mesh of this.morphTargets) {
        if (morphTargetName) {
          if (morphTargetName === mesh.name) {
            found = true;
            const tween = gsap.to(mesh.morphTargetInfluences!, {
              endArray: Array.from(this.defaultMorphDict.get(mesh)!),
              duration,
              onComplete: () => {
                resolve(true);
                tween.kill();
              },
            });
            break;
          }
        } else {
          found = true;
          const tween = gsap.to(mesh.morphTargetInfluences!, {
            endArray: Array.from(this.defaultMorphDict.get(mesh)!),
            duration,
            onComplete: () => {
              finishCount++;
              tween.kill();
              if (finishCount === this.morphTargets.length) {
                resolve(true);
              }
            },
          });
        }
      }
      if (!found) {
        reject(new Error(`未找到模型: ${morphTargetName}`));
      }
    });

  resetTalkMorphSmooth = (duration = 1, morphTargetNames?: string[]) =>
    new Promise((resolve, reject) => {
      let finishCount = 0;
      let found = false;
      if (!morphTargetNames) {
        morphTargetNames = this.talkMorphTargetNames;
      }
      for (const mesh of this.morphTargets) {
        if (morphTargetNames) {
          if (morphTargetNames.includes(mesh.name)) {
            found = true;
            const defaultMorphArray = this.defaultMorphDict.get(mesh)!;
            const defaultTalkMorphDict = Object.entries(
              this.toPlayTalkMorphNames
            ).reduce((prev: any, [key, value]) => {
              prev[key] = defaultMorphArray[value];
              return prev;
            }, {});
            const tween = gsap.to(mesh!, {
              __morphProxy__: defaultTalkMorphDict,
              duration,
              onComplete: () => {
                resolve(true);
                tween.kill();
              },
            });
            break;
          }
        } else {
          found = true;
          const defaultMorphArray = this.defaultMorphDict.get(mesh)!;
          const defaultTalkMorphDict = Object.entries(
            this.toPlayTalkMorphNames
          ).reduce((prev: any, [key, value]) => {
            prev[key] = defaultMorphArray[value];
            return prev;
          }, {} as object);
          const tween = gsap.to(mesh!, {
            __morphProxy__: defaultTalkMorphDict,
            duration,
            onComplete: () => {
              finishCount++;
              tween.kill();
              if (finishCount === this.morphTargets.length) {
                resolve(true);
              }
            },
          });
        }
      }
      if (!found) {
        reject(new Error(`未找到模型: ${morphTargetNames}`));
      }
    });

  mixAnimation = (animations?: THREE.AnimationClip[]): void => {
    if (!animations || animations.length === 0) {
      return;
    }
    if (!this.mixer) {
      this.mixer = new THREE.AnimationMixer(this.scene);
    }
    console.log('mixAnimation initial mixer load time:', performance.now() - window.startTime);
    // 将模型的场景加入到当前场景中播放动画
    const mixer = this.mixer;
    for (const clip of animations) {
      // 去除开头的第一个Armature|
      const name = clip.name.startsWith('Armature|')
        ? clip.name.replace('Armature|', '')
        : clip.name;
      console.log('clip.name ===>'+ clip.name);
      const action = new Action(mixer.clipAction(clip), name) ;
      action.on('finish', () => {
        if (!this.isInteracting) {
          //非交互状态，正常IDLE状态
          if (this.idleLoopActions?.length && this.idleLoopActions?.length < 2) {
            const idleAction = this.actionMap.get(this.idleLoopActions![0])!
            idleAction?.setConfig({
              loop: THREE.LoopRepeat,
              repetitions: Infinity,
            })
            idleAction?.play();
          } else {
            if(action.name === this.idleLoopActions![0]){
              const idleAction =  this.actionMap.get(this.idleLoopActions!.filter((name) => name !== action.name)[Math.floor(Math.random()*this.idleLoopActions!.filter((name) => name !== action.name).length)]);
              this.playAction(idleAction!.name);

            }else{
              const idleAction = this.actionMap.get(this.idleLoopActions![0])!
              idleAction?.setConfig({
                loop: THREE.LoopRepeat,
                repetitions: 2,
              })
              idleAction?.play();
            }
          }
        } else {
          //交互状态
          console.log("isInteracting?"+this.isInteracting);
          console.log("isTalking?"+this.isTalking);
          if(this.isTalking){
            const talkingidleAction = this.actionMap.get(this.talkingidleLoopActions![0])!
            talkingidleAction?.setConfig({
                loop: THREE.LoopRepeat,
                repetitions: Infinity,
              })
              talkingidleAction?.play();
          }else{
            if (this.interactngidleLoopActions?.length && this.interactngidleLoopActions?.length < 2) {
              const interactngidleAction = this.actionMap.get(this.interactngidleLoopActions![0])!
              interactngidleAction?.setConfig({
                loop: THREE.LoopRepeat,
                repetitions: Infinity,
              })
              interactngidleAction?.play();
            } else {
              const interactngidleAction = this.actionMap.get(this.interactngidleLoopActions!.filter((name) => name !== action.name)[Math.floor(Math.random()*this.interactngidleLoopActions!.filter((name) => name !== action.name).length)]);
              this.playAction(interactngidleAction!.name);
            }
          }
        }
      })
      
      
      this.actionMap.set(name, action);
    }
    console.log('mixAnimation forloop load time:', performance.now() - window.startTime);
    if (!this.loop) {
      this.loop = true;
      this.hall.addRenderFn(() => {
        const mixerUpdateDelta = this.clock.getDelta();
        this.mixer!.update(mixerUpdateDelta);
      });
    }
  };

  getAction = (name: string): Action | undefined =>
    this.actionMap.get(name);

  private getWeightActions = (exclude?: string) => {
    const result: Action[] = [];
    this.actionMap.forEach((action) => {
      //console.log('action %s effective weight is %s', action.name, action.action.weight)
      if (action.name !== exclude && action.action.getEffectiveWeight() === 1 && action.enabled) {
        result.push(action)
      }
    })
    return result;
  }

  //清除正在循环的动作
  clearActionLoop = () => {
    this.actionMap.forEach((action) => {
      action.break();
      action.setConfig({
        loop: THREE.LoopOnce,
      });
    })
  }

  playAction = async (
    name: string,
    config?: ActionConfig
  ): Promise<{ break?: boolean; finish?: boolean }> => {
    const action = this.actionMap.get(name);
    if (!action) {
      console.warn(`未找到动作: ${name}`);
      return Promise.reject(new Error(`未找到动作: ${name}`));
    }
    const prevActions = this.getWeightActions();
    action.setConfig(config);
    if (prevActions.length) {
      prevActions.forEach((prev) => {

        if (prev !== action) {
          //console.log('prev action name: ', prev.name)
          prev.crossFadeTo(action)
        } else {
          prev.action.stopFading()
        }
      })
    } else {
      action.play()
    }
    return action.waitFinishOrBreak()
  };

  exchangeIdleAndInteract = () => {

    this.isInteracting = this.isInteracting ? false : true ;
    //console.log(this.isInteracting);
    this.clearActionLoop();
    if (!this.isInteracting) {
      this.playAction(this.idleLoopActions![0]);
    }
    //this.mixAnimation(this.gltfanimations);
  }

  resolveTalkMorphNames = (faceDict: string[]) => {
    this.toPlayTalkMorphNames = {};
    for (let i = 0; i < faceDict.length; i++) {
      const faceName = faceDict[i];
      if (faceName === '-' || faceName === '_') {
        continue;
      }
      this.toPlayTalkMorphNames[faceName] = i;
    }
  };

  playTalk = async (
    audioPath: string,
    faces: number[][],
    meshNames?: string[],
    scale = 1.5,
    delay = 300,
    action?: string,
  ) => {
    console.log("start playing");
    if (!meshNames) {
      meshNames = this.talkMorphTargetNames;
    }
    const morphTargets = this.morphTargets.filter((mesh) =>
      meshNames ? meshNames.includes(mesh.name) : true
    );
    this.emit('onBeforePlayTalk', morphTargets);
    let lastRenderTime = performance.now();
    const drawFaceLoop = () => {
      const now = performance.now();
      const jumpFrame = Math.ceil((now - lastRenderTime - delay) / (1000 / 30));

      if (jumpFrame >= 0 && jumpFrame < faces.length) {
        const currentFace = faces[jumpFrame];
        for (const mesh of morphTargets) {
          for (const [faceName, i] of Object.entries(
            this.toPlayTalkMorphNames
          )) {
            if (mesh.morphTargetDictionary![faceName] !== undefined) {
              mesh.morphTargetInfluences![
                mesh.morphTargetDictionary![faceName]
              ] = currentFace[i] * scale;
            }
          }
        }
      } else if (jumpFrame >= 0) {
        this.hall.removeRenderFn(drawFaceLoop);
      }
    };
    try {
      if(action && action !== ''){
        this.playAction(action);
      } else {
        this.clearActionLoop();
      }
      this.isTalking = true;
      // await this.resetTalkMorphSmooth(0.5, meshNames);
      await new Promise((resolve, reject) => {
        const audio = new Howl({
          src: [audioPath],
          volume: this.talkVolume,
          onplay: () => {
            if (morphTargets.length) {
              lastRenderTime = performance.now();
              this.hall.addRenderFn(drawFaceLoop);
            }
          },
          onend: () => {
            if (morphTargets.length) this.hall.removeRenderFn(drawFaceLoop);
            audio.unload();
            resolve(true);
          },
          onloaderror: (soundId: number, error: unknown) => {
            if (morphTargets.length) this.hall.removeRenderFn(drawFaceLoop);
            reject(error);
          },
          onplayerror: (soundId: number, error: unknown) => {
            if (morphTargets.length) this.hall.removeRenderFn(drawFaceLoop);
            reject(error);
          },
        });
        audio.play();
      });
      //await this.resetTalkMorphSmooth(0.5, meshNames);
    } catch (e) {
      this.emit('onErrorPlayTalk', morphTargets, e);
    } finally {
      this.isTalking = false;
      this.emit('onAfterPlayTalk', morphTargets);
    }
  };

  blinkEyes = (blinkMorphNames: string[], meshNames?: string[]) => {
    if (!meshNames) {
      meshNames = this.blinkMorphTargetNames;
    }
    const morphTargets = this.morphTargets.filter((mesh) =>
      meshNames ? meshNames.includes(mesh.name) : true
    );

    const blinkData: number[] = [];
    for (let loopTime = 0; loopTime < LOOP_BLINK_TIME; loopTime++) {
      blinkData.push(...BLINK_VALUE);
      // 创建一定的睁眼时间，避免频繁眨眼
      const openEyesTime = randIntChoice(
        MIN_BLINK_INTERVAL,
        MAX_BLINK_INTERVAL
      );
      for (let i = 0; i < openEyesTime; i++) {
        blinkData.push(0);
      }
    }
    let lastRenderTime = performance.now();
    // 确保从第一帧开始;
    let index = -1;
    const blinkLoop = () => {
      const now = performance.now();
      const jumpFrame = Math.ceil((now - lastRenderTime) / (1000 / 30));
      lastRenderTime = now;
      index = (index + jumpFrame) % blinkData.length;
      for (const mesh of morphTargets) {
        for (const name of blinkMorphNames) {
          if (mesh.morphTargetDictionary![name] !== undefined) {
            mesh.morphTargetInfluences![mesh.morphTargetDictionary![name]] =
              blinkData[index];
          }
        }
      }
    };
    this.hall.addRenderFn(blinkLoop);
  };

  getcenter() {
    if (this.center) {
      return this.center;
    }
    const box3 = new THREE.Box3();
    box3.expandByObject(this.scene);
    const center = new THREE.Vector3();
    box3.getCenter(center);
    this.center = center
    return center
  };

  clearTalkMorph() {
    console.log('clearTalkMorph');
    this.morphTargets.forEach((mesh) => {
      for (const [faceName, i] of Object.entries(this.toPlayTalkMorphNames)) {
        if (mesh.morphTargetDictionary![faceName] !== undefined) {
          mesh.morphTargetInfluences![mesh.morphTargetDictionary![faceName]] = 0;
        }
      }
    });
  }
}




export default Character;
