import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
  } from 'react';
  import { CSRES, chatcsQianqian, getqianqianReplyTTS } from './axios/api';
  // import useASR, { IASRConfig } from './hooks/useASR';
  import {
    useGetState,
    useMemoizedFn,
    useMount,
    useUpdateEffect,
    useFullscreen,
  } from 'ahooks';
  import styled from 'styled-components';
  import * as Three from 'three';
  // import { Bezier } from 'bezier-js';
  import voiceJson from '@/assets/voice.json';
  import ExhibitionHall, {
    Character,
    ExhibitionHallConfiguration,
  } from './exhibition-hall';
  import { FArkit28_TTS_BLEND_SHAPES, BKX_TTS_BLEND_SHAPES } from './ledi-talk-morph-dict'
  import { rem } from './util';
  import MainBg from './components/MainBg';
  
  // export const ASR_CONFIG: IASRConfig = {
  //   secretKey: 'yhGoeGm26ub7axDRaYhaLuGzXxJSQ8Zp',
  //   secretId: 'AKIDQVkjRwMejYK8lCRUKuVxCE0b5MVSAi05',
  //   appId: 1310500600,
  // };
  
  enum SESSION_STATE {
    CLOSED = 0,
    BEGIN_RECOGNIZE = 1, // 0 开始
    AFTER_RECOGNIZE = 2, // 1 人说话
    REPLY = 3, // 2 虚拟人回复
  }

  const clock = new Three.Clock();

  function delay(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  
  //Hook msg to SDK
  export const hookSDKmsg = (param: any, type: string) => {
    const sendmsg = {
      type: type,
      data: param,
    };
    // window.parent.postMessage(sendmsg);
  };
  
  // const LottieContainer = styled.div<{ show: number }>`
  //   visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  //   position: absolute;
  //   left: 50%;
  //   bottom: 2.3rem;
  //   transform: translateX(-50%);
  //   width: 3.2rem;
  //   z-index: 100;
  // `;
  
  const CHARACTER_LEDI_CONFIG: ExhibitionHallConfiguration = {
    loadingCover: true,
    lights: false,
    //helper: true,
    fps: 25,
    orbitControls: {
      enablePan: true,
      enableZoom: true,
      enableRotate: false,
      enableRotateX: true,
      enableRotateY: false,
    },
    loaders: {
      gltf: {
        dracoLoaderPath: '/draco/gltf/',
      },
      hdr: false,
    },
    camera: {
      // type: 'orthographic',
      fov: 35,
      near: 1,
      far: 200,
      position: [-0.0, 1.82, 2.8], //头部
      //position: [0, 0.55, 4.2], //全身
      focus: [0, 1.45, 1.5],
    },
  }; 
  
  async function getReplyTTS(text: string) {
    //const { audio } = await getLediTTS(text);
    //const { blendshapes } = await getFaces(audio);
    const FFTTSAndBS = await getqianqianReplyTTS(text);
    const audio = FFTTSAndBS.audio;
    const blendshapes = FFTTSAndBS.blendshapes
    return { audio, blendshapes };
  }
  
  
  function Testan9() {
    const [loaded, setLoaded] = useState(false);
    const [sessionState, setSessionState, getSessionState] = useGetState(
      SESSION_STATE.CLOSED
    );
    const [humanText, setHumanText, getHumanText] = useGetState('');
    const [vhumanText, setVHumanText, getVHumanText] = useGetState('');
    const [chatRes, setChatRes, getChatRes] = useGetState<CSRES | null>(null);
    //const [chatRes, setChatRes, getChatRes] = useGetState<WORKFLOWRES | null>(null);
    // const { startRecognize, endRecognize, getRecognizeText, authUseMicrophone } =
    //   useASR(ASR_CONFIG);
    const containerRef = useRef<HTMLDivElement>(null);
    const hallRef = useRef<ExhibitionHall | null>(null);
    const humanRef = useRef<Character | null>(null);
    const [_, { toggleFullscreen }] = useFullscreen(document.body);
    const mixerRef = useRef<THREE.AnimationMixer>();
    const modelRef = useRef<THREE.Scene>();
    useLayoutEffect(() => {
      const hall = (hallRef.current = new ExhibitionHall(
        containerRef.current!,
        CHARACTER_LEDI_CONFIG
      ));
      // hall.renderer.outputEncoding = Three.sRGBEncoding;
      // this.renderer.physicallyCorrectLights = true
      hall.renderer.toneMapping = Three.ACESFilmicToneMapping;
      hall.renderer.toneMappingExposure = 0.8;
      hall.renderer.shadowMap.enabled = false;
      hall.renderer.setPixelRatio(window.devicePixelRatio * 0.1);
      const directionalLight = new Three.DirectionalLight(0xffffff);
      //directionalLight.position.set(5, -5, 10);
      directionalLight.position.x = -3;
      directionalLight.position.y = 2;
      directionalLight.position.z = 6;
      directionalLight.intensity = 1;
      directionalLight.castShadow = true;
      directionalLight.shadow.camera.top = 2;
      directionalLight.shadow.camera.bottom = -2;
      directionalLight.shadow.camera.left = -2;
      directionalLight.shadow.camera.right = 2;
      directionalLight.shadow.camera.near = 1;
      directionalLight.shadow.camera.far = 40;
      directionalLight.shadow.mapSize.width = 1024;
      directionalLight.shadow.mapSize.height = 1024;
      //hall.scene.add(directionalLight);
  
      //光投影相机
      // const cam = directionalLight.shadow.camera;
      // const cameraHelper = new Three.CameraHelper( cam );
      // hall.scene.add( cameraHelper );
      // cameraHelper.visible = true;
  
      // 光源
      // const helper = new Three.DirectionalLightHelper( directionalLight );
      // hall.scene.add( helper );
      // helper.visible = true;

      const doTalkText = async (reply: any) => {
        const fns = [];
        setVHumanText(reply);
        const replyarr = reply.split(/([。|，|；|;|！|!]+)/);
        //loop replyarr if item.length < 5, then add next item to it
        for (let i = 0; i < replyarr.length; i++) {
          if (/([。|，|；|;|！|!]+)/.test(replyarr[i])) {
            replyarr[i-1] = replyarr[i-1] + replyarr[i];
            replyarr.splice(i, 1);
          }
        }
        //console.log(replyarr);
        for (let i = 0; i < replyarr.length - 1; i++) {
          if (replyarr[i].length < 5) {
            replyarr[i] = replyarr[i] + replyarr[i + 1];
            replyarr.splice(i + 1, 1);
          }
        }
        //for (const reply of replies.content.text.split(/([。|；|;|！|!]+)/)) {
        for (const reply of replyarr) {
          if (reply !== '') {
            fns.push(() => getReplyTTS(reply));
          }
        }
        let prePromise = fns[0]();
        for (let i = 1; i <= fns.length; i++) {
          const { audio, blendshapes } = await prePromise;
          prePromise = fns[i]?.();
          await humanRef.current?.playTalk(
            'data:audio/wav;base64,' + audio,
            blendshapes,//blendshapes.slice(0, -5),
            undefined,
            4,
            100,
          );
        }
        await humanRef.current?.resetTalkMorphSmooth(0.5);
      };
      window.doTalkText = doTalkText;

      const playAction = async (action: string) => {
        await humanRef.current?.playAction(action);
      };
      window.playAction = playAction;
  
      const geometry = new Three.PlaneGeometry( 10, 10 );
      const material = new Three.MeshStandardMaterial( {
        emissiveIntensity: 1,
        //color: 0x000000, 
        color: 0x6dd8ed
        //side: Three.DoubleSide
      } );
      // const plane = new Three.Mesh( geometry, material );
      // plane.castShadow = true;
      // plane.receiveShadow = true;
      // hall.scene.add( plane ); 
  
      // Add lights
      const hemiLight = new Three.HemisphereLight(0xf7f7f2, 0xffffff, 0.2);
      hemiLight.position.set(0, 50, 0);
      // Add hemisphere light to scene
      //hall.scene.add(hemiLight);
      // const texture = hall.loadTexture(
      //   //'/zhaoshangniuniu/aofei/cjfx/hdr/venice_sunset_1k.hdr'
      //   '/zhaoshangniuniu/aofei/cjfx/hdr/kloofendal_48d_partly_cloudy_puresky_1k.hdr'
      // );
      // texture.mapping = Three.EquirectangularRefractionMapping;
      // 给场景添加背景
       //hall.scene.background = texture
      // 给场景所有的物体添加默认的环境贴图, 设置后不用在geometry设置envmap
      //hall.scene.environment = texture;
      // hall.scene.backgroundBlurriness = 0.3;
      // @ts-ignore
      window.hall = hall;
      window.startTime = performance.now();
      hall
        // .loadGLTF(
        //   'https://development-resource.oss-cn-beijing.aliyuncs.com/zhaoshangniuniu/aofei/cjfx/xiaofeixia%EF%BC%8Bidle526_1.glb',
        //   'anqi'
        // )ledi15_719.glb
        // .loadGLTF('1011_1.glb', 'dsl')
        .loadGLTF('https://3dassets.obs.cn-east-3.myhuaweicloud.com/test/1011_1.glb', 'ledi')
        // .loadGLTF('/zhaoshangniuniu/aofei/tests/xyyblue_bodyname_20240612_2.glb', 'ledi')
        .then((gltf) => {
          console.log('test=============');
          console.log(gltf);
          console.log('gltf resolved time:', performance.now() - window.startTime);
          const character = (humanRef.current = new Character(
            'dsl',
            gltf,
            hall,
            {
              //blinkMorphNames: ['eyeBlinkLeft', 'eyeBlinkRight'],
              //blinkMorphNames: ['eye_close_dn'],
              // idleAction: 'BK_01',
              idleAction: 'idle3',
              idleLoopActions: ['idle', 'idle3'],
              interactngidleLoopActions: ['idle'],
              //talkingidleLoopActions: ['zhuan','bei','zhi'],
              position: [0, -0.0, 0],
              talkMorphNames: FArkit28_TTS_BLEND_SHAPES,
              // talkMorphNames: BKX_TTS_BLEND_SHAPES,
              //talkMorphTargetNames: ['face',],
              //blinkMorphTargetNames: ['JETT_1'],
            }
          ));
          console.log('character load time:', performance.now() - window.startTime);
          // @ts-ignore
          window.character = character;
          mixerRef.current = character.mixer as THREE.AnimationMixer;
          modelRef.current = gltf.scene;
  
          //hall.renderer.setAnimationLoop(hall.shaderanimate);
          hall.renderLoop();
          console.log('character renderLoop time:', performance.now() - window.startTime); 
          setLoaded(true);
          hookSDKmsg('GlbLoadComplete', 'GlbLoadCompleteHook');
          hall.hideCover();
        });
  
      return hall.dispose;
    }, []);
    // useMount(authUseMicrophone);
    useUpdateEffect(() => {
      if (sessionState === SESSION_STATE.CLOSED) {

        reset();
      } else if (sessionState === SESSION_STATE.BEGIN_RECOGNIZE) {
        reset();
        // startRecognize({
        //   onSentenceEnd: () => {
        //     setTimeout(() => {
        //       const recognizeText = getRecognizeText();
        //       if (!recognizeText) {
        //         return;
        //       }
        //       if (getSessionState() !== SESSION_STATE.BEGIN_RECOGNIZE) {
        //         return;
        //       }
        //       console.log('recognizeText text ==> ' + recognizeText);
        //       setHumanText(recognizeText);
        //       setSessionState(SESSION_STATE.AFTER_RECOGNIZE);
        //     });
        //   },
        // });
      } else if (sessionState === SESSION_STATE.AFTER_RECOGNIZE) {
        // endRecognize();
        const humanText = getHumanText() || '开始';
        console.log('cog text ==> ' + humanText);
        if (humanText) {
          /**
           * chatcs
           */
          chatcsQianqian(humanText)
            .then((res) => {
              setChatRes(res);
              setSessionState(SESSION_STATE.REPLY);
            })
            .catch((e) => {
              console.error(e);
              if (getSessionState() !== SESSION_STATE.AFTER_RECOGNIZE) {
                return;
              }
              setSessionState(SESSION_STATE.BEGIN_RECOGNIZE);
            });
          
          /**chatworkflow 
          chatworkflowLedi(humanText)
            .then((res) => {
              setChatRes(res);
              setSessionState(SESSION_STATE.REPLY);
            })
            .catch((e) => {
              console.error(e);
              if (getSessionState() !== SESSION_STATE.AFTER_RECOGNIZE) {
                return;
              }
              setSessionState(SESSION_STATE.BEGIN_RECOGNIZE);
            });
          */
        }
      } else if (sessionState === SESSION_STATE.REPLY) {
        const res = getChatRes();
        console.log('res=>', res);
        const replayHandler = async () => {
          const fns = [];
          for (const reply of res?.replies ?? []) {
            setVHumanText(reply.text);
            const replyarr = reply.text.split(/([。|，|；|;|！|!]+)/);
            //loop replyarr if item.length < 5, then add next item to it
            for (let i = 0; i < replyarr.length; i++) {
              if (/([。|，|；|;|！|!]+)/.test(replyarr[i])) {
                replyarr[i-1] = replyarr[i-1] + replyarr[i];
                replyarr.splice(i, 1);
              }
            }
            //console.log(replyarr);
            for (let i = 0; i < replyarr.length - 1; i++) {
              if (replyarr[i].length < 5) {
                replyarr[i] = replyarr[i] + replyarr[i + 1];
                replyarr.splice(i + 1, 1);
              }
            }
            //for (const reply of replies.content.text.split(/([。|；|;|！|!]+)/)) {
            for (const reply of replyarr) {
              if (reply !== '') {
                fns.push(() => getReplyTTS(reply));
              }
            }
          }
          let prePromise = fns[0]();
          for (let i = 1; i <= fns.length; i++) {
            const { audio, blendshapes } = await prePromise;
            prePromise = fns[i]?.();
            await humanRef.current?.playTalk(
              'data:audio/wav;base64,' + audio,
              blendshapes,//blendshapes.slice(0, -5),
              undefined,
              1.2,
              100,
            );
          }
          await humanRef.current?.resetTalkMorphSmooth(0.5);
        };
        replayHandler().finally(() => {
          reset();
          if (getSessionState() !== SESSION_STATE.REPLY) {
            return;
          }
          setSessionState(SESSION_STATE.BEGIN_RECOGNIZE);
        });
      }
      // return endRecognize;
    }, [sessionState]);

    const animate = (time: number) => {
      //requestAnimationFrame(animate);
      // const uniforms1 = {
      //   time: { type: "f", value: 1.0 },
      //   resolution: { type: "v2", value: new Three.Vector2() }
      // };
      // if (mixerRef.current) {
      //   const delta = clock.getDelta();
			//   uniforms.time.value += delta * 5;
      //   mixerRef.current.update(delta);
      //   // if (hallRef.current && hallRef.current.scene) {
      //   //   hallRef.current.scene.rotation.y += 0.001;
      //   // }
      //   hallRef.current?.renderer.render(hallRef.current?.scene, hallRef.current?.camera);
      //   // mixerRef.current.update(time / 1000);
      // }
  
      // if (modelRef.current) {
      //   modelRef.current.traverse((child) => {
      //     //if (child.type === 'SkinnedMesh') {
      //     if (child instanceof Three.Mesh) {
      //       const skeleton = (child as THREE.SkinnedMesh).skeleton;
      //       if (skeleton) {
      //         const bones = skeleton.bones;
      //         const boneMatrices = new Float32Array(bones.length * 16); // 每个矩阵16个浮点数
  
      //         for (let i = 0; i < bones.length; i++) {
      //           bones[i].updateMatrixWorld(); // 更新世界矩阵
      //           bones[i].matrixWorld.toArray(boneMatrices, i * 16); // 将矩阵转换为数组
      //         }
  
      //         //const material = skinnedMesh.material as THREE.ShaderMaterial;
      //         const material = (child as THREE.SkinnedMesh).material as THREE.ShaderMaterial;
      //         material.uniforms.boneMatrices.value = boneMatrices; // 更新Uniform
      //       }
      //     }
      //   });
      // }
    };
    const reset = useCallback(() => {
      // setCommand(null);
      humanRef.current?.clearTalkMorph();
      setHumanText('');
      setVHumanText('');
      setChatRes(null);
    }, []);
  
    const toggle = useMemoizedFn(() => {
      setHumanText('');
      if (getSessionState() !== SESSION_STATE.CLOSED) {
        humanRef.current?.exchangeIdleAndInteract();
        chatcsQianqian('结束');
      } else {
        humanRef.current?.exchangeIdleAndInteract();
      }
      setSessionState((s) => (s > 0 ? 0 : 1));
      
    });
  
    return (
      <div className='common_main'>
        {/* <MainBg /> */}
        <FullScreenButton onTouchStart={toggleFullscreen} />
        {loaded && (
          <>
            {/* <ZanButton
              onTouchStart={() => {
                humanRef.current?.playAction('bei');
              }}
            /> */}
             {/* <ZhaohuButton
              onTouchStart={() => {
                humanRef.current?.playAction('Hello01');
              }}
              onClick={() => {
                humanRef.current?.playAction('Hello01');
              }}
            /> */}
            {/* <QuanButton
              onTouchStart={() => {
                humanRef.current?.playAction('04');
              }}
            /> */}
            {/* {sessionState === SESSION_STATE.CLOSED ? (
              // <TalkButton onTouchStart={toggle} />
              <TalkButton onClick={toggle} />
            ) : (
              <StopButton onClick={toggle} />
            )} */}
          </>
        )}
        <div
          style={{
            // width: '10rem',
            // height: '20rem', //three 渲染的canvas高度
            //width: '600px',
            //height: '600px', //three 渲染的canvas高度
            width: '100%',
            height: '100%', //three 渲染的canvas高度
            // border: '1px solid red',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transformOrigin: 'center center',
            transform: 'translate(-50%, -50%)',
          }}
          ref={containerRef}
        ></div>
        {/* <LottieContainer
          show={Number(sessionState === SESSION_STATE.BEGIN_RECOGNIZE)}
        >
          <Lottie animationData={voiceJson} loop></Lottie>
        </LottieContainer> */}
        <div
          style={{
            position: 'absolute',
            zIndex: 99999,
            left: 16,
            right: 16,
            top: 40,
            fontSize: 14,
          }}
        >
          {humanText}
        </div>
        <div
          style={{
            position: 'absolute',
            zIndex: 99999,
            left: 16,
            right: 16,
            bottom: 16,
            fontSize: 14,
          }}
        >
          {vhumanText}
        </div>
      </div>
    );
  }
  
  const TalkButton = styled.div`
    position: absolute;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2.462963rem;
    background: url(/wakeup.png) no-repeat no-repeat center center / cover;
    width: ${rem(1477)};
    height: ${rem(939)};
    cursor: pointer;
  `;
  
  const StopButton = styled.div`
    position: absolute;
    z-index: 9;
    right: ${rem(60)};
    top: ${rem(1770)};
    background: url(/stop.png) no-repeat no-repeat center center / cover;
    width: ${rem(365)};
    height: ${rem(365)};
    cursor: pointer;
  `;
  
  const ZanButton = styled.div`
    position: absolute;
    z-index: 9;
    left: 0;
    top: ${rem(1870)};
    background: url(/doaction.png) no-repeat no-repeat center center / cover;
    width: ${rem(495)};
    height: ${rem(176)};
    cursor: pointer;
  `;
  
  const ZhaohuButton = styled.div`
    position: absolute;
    z-index: 9;
    left: 0;
    top: ${rem(1620)};
    background: url(/zhaohu.png) no-repeat no-repeat center center / cover;
    width: ${rem(495)};
    height: ${rem(176)};
    cursor: pointer;
  `;
  
  const QuanButton = styled.div`
    position: absolute;
    z-index: 9;
    left: 0;
    top: ${rem(2120)};
    background: url(/meng.png) no-repeat no-repeat center center / cover;
    width: ${rem(495)};
    height: ${rem(176)};
    cursor: pointer;
  `;
  
  const FullScreenButton = styled.div`
    position: absolute;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    top: 1.3rem;
    width: ${rem(850)};
    height: ${rem(200)};
    cursor: pointer;
    /* border: 1px solid red; */
  `;
  
  export default Testan9;
  