import { Navigate, BrowserRouter as Router, useRoutes } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import Testan9 from './testan9';

export const route: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/cube" />,
  },
  {
    path: '/cube',
    element: <Testan9 />,
  },
];

export const RenderRoutes = () => {
  return useRoutes(route);
};

export default function RootRouter() {
  return (
    <Router>
      <RenderRoutes />
    </Router>
  );
}
