import * as THREE from 'three';
import { LoadingManager } from './loading-manager';

export function LoadTextureCorrected(
  _loader: THREE.TextureLoader,
  _path: string,
  mManager: LoadingManager
) {
  mManager.register(_path);
  // Load the texture
  const texture = _loader.load(
    _path,
    () => {},
    (pe) => {
      mManager.set(_path, pe.loaded, pe.total);
      mManager.handleProcess();
    },
    () => {
      mManager.setFailed();
    }
  );

  // console.log(_path, texture)

  // Set repeat wrapping
  texture.wrapT = THREE.RepeatWrapping;
  texture.wrapS = THREE.RepeatWrapping;

  texture.encoding = THREE.sRGBEncoding;
  // Flip texture vertically
  texture.repeat.y = -1;
  texture.needsUpdate = true;
  // Return the corrected texture
  return texture;
}
