const TALK_BS_LIST: string[] = [
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'Jaw_FW',
  'Jaw_L',
  'Jaw_R',
  'E',
  'M_dn',
  'M_up',
  'U',
  'M_L',
  'M_R',
  'smile01_L',
  'smile01_R',
  'M_L_Dn',
  'M_R_Dn',
  'M_L_Up',
  'M_R_Up',
  'sad_L',
  'sad_R',
  'upLip_MID_dn',
  'lowLip_MID_up',
  'lowLip_FW',
  'upLip_Fw',
  'M_L_outUp',
  'M_R_outUp',
  'lowLip_L_dn',
  'lowLip_R_dn',
  'upLip_L_up',
  'upLip_R_up',
];

export const AnqiTalkingFaceDict = [
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'jawForward',
  'jawRight',
  'jawLeft',
  'jawOpen',
  'mouthClose',
  'mouthFunnel',
  'mouthPucker',
  'mouthRight',
  'mouthLeft',
  'mouthSmileLeft',
  'mouthSmileRight',
  'mouthFrownLeft',
  'mouthFrownRight',
  'mouthDimpleLeft',
  'mouthDimpleRight',
  'mouthStretchLeft',
  'mouthStretchRight',
  'mouthRollLower',
  'mouthRollUpper',
  'mouthShrugLower',
  'mouthShrugUpper',
  'mouthPressLeft',
  'mouthPressRight',
  'mouthLowerDownLeft',
  'mouthLowerDownRight',
  'mouthUpperUpLeft',
  'mouthUpperUpRight',
  '-',
  '-',
  '-',
  '-',
  '-',
  'cheekPuff',
  'cheekSquintLeft',
  'cheekSquintRight',
  'noseSneerLeft',
  'noseSneerRight',
  'tongueOut',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
];


// 共61个 28bs版本
export const Ledi_TTS_28BLEND_SHAPES = [
  'Jaw_FW',
  'Jaw_L', // 'jawRight',
  'Jaw_R', // 'jawLeft',
  'E',
  'M_dn',
  'M_up', //'mouthFunnel',
  'U',
  'M_L', //'mouthRight',
  'M_R', //'mouthLeft',
  'smile01_L',
  'smile01_R',
  'M_L_Dn',
  'M_R_Dn',
  'M_L_Up',
  'M_R_Up',
  'sad_L',
  'sad_R',
  'upLip_MID_dn',
  'lowLip_MID_up',
  'lowLip_FW',
  'upLip_Fw',
  'M_L_outUp',
  'M_R_outUp',
  'lowLip_L_dn',
  'lowLip_R_dn',
  'upLip_L_up',
  'upLip_R_up',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'tongueOut',
];

// 共61个 28bs版本
export const AZURE_TTS_BLEND_SHAPES = [
  'jawForward',
  '-', // 'jawRight',
  '-', // 'jawLeft',
  'jawOpen',
  'mouthClose',
  '-', //'mouthFunnel',
  'mouthPucker',
  '-', //'mouthRight',
  '-', //'mouthLeft',
  'mouthSmileLeft',
  'mouthSmileRight',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'mouthLowerDownLeft',
  'mouthLowerDownRight',
  'mouthUpperUpLeft',
  'mouthUpperUpRight',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'tongueOut',
];


export const Arkit28_TTS_BLEND_SHAPES = [
  'jawForward',
  'jawRight', // 'jawRight',
  'jawLeft', // 'jawLeft',
  'jawOpen',
  'mouthClose',
  'mouthFunnel', //'mouthFunnel',
  'mouthPucker',
  'mouthRight', //'mouthRight',
  'mouthLeft', //'mouthLeft',
  'mouthSmileLeft',
  'mouthSmileRight',
  'mouthFrownLeft',
  'mouthFrownRight',
  'mouthDimpleLeft',//
  'mouthDimpleRight',//
  'MouthStretchLeft',
  'MouthStretchRight',
  'MouthRollLower',
  'MouthRollUpper',
  'MouthShrugLower',
  'MouthShrugUpper',
  'MouthPressLeft',
  'MouthPressRight',
  'MouthLowerDownLeft',
  'MouthLowerDownRight',
  'MouthUpperUpLeft',
  'MouthUpperUpRight',
  'tongueOut',//tongueOut tongueOut
];

//export default TALK_BS_LIST;
export const BKX_TTS_BLEND_SHAPES = [
  '-',
  'jewAR', // 'jawRight',
  'jewAR', // 'jawLeft',
  'jewB',
  '-', //jewA',
  '-', //'mouthFunnel',
  '-',
  '-', //'mouthRight',
  '-',
  'L_M_cornersA',
  'R_M_cornersA',
  '-',
  '-',
  '-',
  '-',
  'L_M_cornersB',
  'R_M_cornersB',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  'LUlipB',
  'RUlipB',
  'LUlipA',
  'RUlipA',
  '-',
];

export const FArkit28_TTS_BLEND_SHAPES = [
  'jawForward',
  'jawRight', // 'jawRight',
  'jawLeft', // 'jawLeft',
  'mouthOpen',
  'mouthClose',
  'mouthFunnel', //'mouthFunnel',
  'mouthPucker',
  'mouthSmile', //'mouthRight',
  'mouthLeft', //'mouthLeft',
  'mouthSmileLeft',
  'mouthSmileRight',
  'mouthFrownLeft',
  'mouthFrownRight',
  'mouthDimpleLeft',//
  'mouthDimpleRight',//
  'MouthStretchLeft',
  'MouthStretchRight',
  'MouthRollLower',
  'MouthRollUpper',
  'MouthShrugLower',
  'MouthShrugUpper',
  'MouthPressLeft',
  'MouthPressRight',
  'MouthLowerDownLeft',
  'MouthLowerDownRight',
  'MouthUpperUpLeft',
  'MouthUpperUpRight',
  'tongueOut',//tongueOut tongueOut
];