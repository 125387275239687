import service, { IResponse } from '@/axios/index';
import { nanoid } from 'nanoid';
import crypto from 'crypto-js';

const _lut: any[] = [];

for ( let i = 0; i < 256; i ++ ) {

	_lut[ i ] = ( i < 16 ? '0' : '' ) + ( i ).toString( 16 );

}
function generateUUID() {
	const d0 = Math.random() * 0xffffffff | 0;
	const d1 = Math.random() * 0xffffffff | 0;
	const d2 = Math.random() * 0xffffffff | 0;
	const d3 = Math.random() * 0xffffffff | 0;
	const uuid = _lut[ d0 & 0xff ] + _lut[ d0 >> 8 & 0xff ] + _lut[ d0 >> 16 & 0xff ] + _lut[ d0 >> 24 & 0xff ] + '-' +
			_lut[ d1 & 0xff ] + _lut[ d1 >> 8 & 0xff ] + '-' + _lut[ d1 >> 16 & 0x0f | 0x40 ] + _lut[ d1 >> 24 & 0xff ] + '-' +
			_lut[ d2 & 0x3f | 0x80 ] + _lut[ d2 >> 8 & 0xff ] + '-' + _lut[ d2 >> 16 & 0xff ] + _lut[ d2 >> 24 & 0xff ] +
			_lut[ d3 & 0xff ] + _lut[ d3 >> 8 & 0xff ] + _lut[ d3 >> 16 & 0xff ] + _lut[ d3 >> 24 & 0xff ];

	// .toUpperCase() here flattens concatenated strings to save heap memory space.
	return uuid.toUpperCase();

}


const senderId = generateUUID();

export const chatOpenapi = (text:string, isEvent = false, senderName = 'TestUser') => {
  const msgId = generateUUID();
  const timestamp = (Date.now() / 1000) | 0;
  const privateKey = 'ad3d895666f64096b546b882217da122';
  const content = {
    "senderId": senderId,
    "senderNickname": senderName,
    "content": {
      "text": isEvent ? '#EMPTY#' : text,
      "metadata": {
        "Character": "commercialcx-0",
        "CharacterName": "xiyangyang"
        },
    },
    "PartnerInfo": {
      "PartnerId": 1473,
      "PartnerName": "xiyangyang"
    },
    "msgId": msgId,
    "timestamp": timestamp
   }
   if (isEvent) {
    Object.assign(content.content.metadata, {
      CallArgs: JSON.stringify({Scenario: text}),
      CallReason: text
    })
   }
   const stringToChecksum = JSON.stringify(content) + privateKey + timestamp;
   const  sha512 = crypto.SHA512(stringToChecksum);
   const signature = sha512.toString(crypto.enc.Hex).replace('-', '');
  return service.post<WORKFLOWRES>('/workflow-api/platform/ReplyV3',
  {
    "senderId": senderId,
    "senderNickname": senderName,
    "content": {
      "text": isEvent ? '#EMPTY#' : text,
      "metadata": {
        "Character": "commercialcx-0",
        "CharacterName": "xiyangyang"
        },
    },
    "PartnerInfo": {
      "PartnerId": 1473,
      "PartnerName": "xiyangyang"
    },
    "msgId": msgId,
    "timestamp": timestamp
   },
  {
    "headers":{
      'subscription-key': '8616fef38e5c405f8adf2d9969ec493d',
      'timestamp': timestamp,
      'signature': signature
    }
  })
}

export const chatqianqianOpenapi = (text:string, isEvent = false, senderName = 'TestUser') => {
  const msgId = generateUUID();
  const timestamp = (Date.now() / 1000) | 0;
  const privateKey = 'fe32536f3d5e47929262059348baab21';
  const content = {
    "senderId": senderId,
    "senderNickname": senderName,
    "content": {
      "text": isEvent ? '#EMPTY#' : text,
      "metadata": {
        "Character": "commercialcx-0",
        "CharacterName": "TestUser"
        },
    },
    "PartnerInfo": {
      "PartnerId": 1456,
      "PartnerName": "xiyangyang"
    },
    "msgId": msgId,
    "timestamp": timestamp
   }
   if (isEvent) {
    Object.assign(content.content.metadata, {
      CallArgs: JSON.stringify({Scenario: text}),
      CallReason: text
    })
   }
   const stringToChecksum = JSON.stringify(content) + privateKey + timestamp;
   const  sha512 = crypto.SHA512(stringToChecksum);
   const signature = sha512.toString(crypto.enc.Hex).replace('-', '');
  return service.post<WORKFLOWRES>('/int-workflow-api/platform/Reply',
  {
    "senderId": senderId,
    "senderNickname": senderName,
    "content": {
      "text": isEvent ? '#EMPTY#' : text,
      "metadata": {
        "Character": "commercialcx-0",
        "CharacterName": "TestUser"
        },
    },
    "PartnerInfo": {
      "PartnerId": 1456,
      "PartnerName": "xiyangyang"
    },
    "msgId": msgId,
    "timestamp": timestamp
   },
  {
    "headers":{
      'subscription-key': 'fe32536f3d5e47929262059348baab21'
    }
  })
}


export async function getXyyReplyTTS(text:string) {
  const key = 'ivU9MLwzTpesXmLak3wcp9Iy1gJvqhQoy6Pj67pjYrA='

  const now = String((Date.now() / 1000) | 0);

  const encryptedData = crypto.HmacSHA256(now, key).toString(crypto.enc.Base64);

  return service.post<FFTTSAndBS>('/face_former_prod/face_former/public_tts_arkit/wx',
  {
    text,
    "tts_options": {
      "voice_id": "181-xiyangyang",
      "channels": 1,
      "frame_rate": 16000,
      "pitch": 1.0,
      "volume": 1.0,
      "speed": 1.0,
      "style": "",
      "role": "",
      "provider": "xiaoice"
    }
  },{
    "headers": {
      'Authorization': `${now}.${encryptedData}`,
      'Trace-Id': generateUUID(),
      }
  })
}


export async function getqianqianReplyTTS(text:string) {
  const key = 'ivU9MLwzTpesXmLak3wcp9Iy1gJvqhQoy6Pj67pjYrA='

  const now = String((Date.now() / 1000) | 0);

  const encryptedData = crypto.HmacSHA256(now, key).toString(crypto.enc.Base64);

  return service.post<FFTTSAndBS>('/face_former_prod/face_former/public_tts_arkit/wx',
  {
    text,
    "tts_options": {
      "voice_id": "181-biaozhunnvsheng012-z8t2",
      // "voice_id": "181-xiyangyang",
      // "voice_id": "131-aixia1",
      "channels": 1,
      "frame_rate": 16000,
      "pitch": 1.0,
      "volume": 1.0,
      "speed": 1,
      "style": "",
      "role": "",
      "provider": "xiaoice"
    }
  },{
    "headers": {
      'Authorization': `${now}.${encryptedData}`,
      'Trace-Id': generateUUID(),
      }
  })
}

export const getTTS = (message: string) => {
  return service.post<{ audio: string }>(
    '/api/v1/speech/direct/organizations/org-cx',
    {
      message,
      voice: 'zh-CN-XiaoxiaoNeural',
      styleCode: 'cheerful',
      speed: 1.1,
      pitch: 1,
    }
  );
};

export const getLediTTS = (message: string) => {
  return service.post<{ audio: string }>(
    '/api/v1/speech/direct/organizations/org-cx',
    {
      message,
      voice: 'zh-CN-YunxiNeural',
      styleCode: 'Boy',
      speed: 1.1,
      pitch: 1,
    }
  );
};

export const getFaces = (audio: string) => {
  const formdata = new FormData();
  formdata.append('audio', audio);
  return service.post<{ blendshapes: number[][] }>(
    '/face_former/predict_arkit',
    formdata
  );
};

export type FFTTSAndBS = {
  audio: string;
  blendshapes: number[][]
};
export const getLediTTSAndBS = (text: string) => {
  const key = 'ivU9MLwzTpesXmLak3wcp9Iy1gJvqhQoy6Pj67pjYrA='

  const now = String((Date.now() / 1000) | 0);

  const encryptedData = crypto.HmacSHA256(now, key).toString(crypto.enc.Base64);

  return service.post<FFTTSAndBS>('/face_former_prod/face_former/public_tts_arkit/wx',
  {
    text,
    "tts_options": {
      "voice_id": "181-xiyangyang",
      "channels": 1,
      "frame_rate": 16000,
      "pitch": 1.0,
      "volume": 1.0,
      "speed": 1.0,
      "style": "",
      "role": "",
      "provider": "xiaoice"
    }
  },{
    "headers": {
      'Authorization': `${now}.${encryptedData}`,
      'Trace-Id': generateUUID(),
      }
  })
};

const CHAT_SESSION = encodeURIComponent(nanoid());

export type CSRES = {
  replies: { emotion: string; gesture: string; text: string }[];
  replyId: string;
};

export const chatcs = (text: string) => {
  return service.post<CSRES>(
    `/cstudio-api/v1/chat/organizations/org-sales/characters/646316548b2eaa0636f47c8a/sessions/${CHAT_SESSION}`,
    { query: text }
  );
};

export const chatcsLedi = (text: string) => {
  return service.post<CSRES>(
    `/cstudio-api/v1/chat/organizations/org-chaojifeixia/characters/648bf1759847243681605f02/sessions/${CHAT_SESSION}`,
    { query: text }
  );
};

export const chatcsQianqian = (text: string) => {
  return service.post<CSRES>(
    `/cstudio-api/v1/chat/organizations/org-development/characters/64141318215a780dedaec7b1/sessions/${CHAT_SESSION}`,
    { query: text }
  );
};

export type WORKFLOWRES = {
  content: { text: string};
  receiverId: string;
  masterId: string;
  msgId: string;
  timestamp: number;
  triggerTime: number;
  status: number;
}[];
export const chatworkflowLedi = (text: string) => {
  return service.post<WORKFLOWRES>(
    `/workflow-api/platform/Reply`,
    { 
      content: {
          text: text,
          ContentType: "text",
          Metadata: {
              Character: "commercialcx-0",
              CharacterName: "chaojifeixia"
          }
      },
      senderId: "xxxxxx",
      timestamp: 1689734325,
      msgId: nanoid(),
      PartnerInfo: {
          PartnerId: 1473,
          PartnerName: "奥飞娱乐"
      }
    },
    {
      "headers":{
        "subscription-key": "8616fef38e5c405f8adf2d9969ec493d"
      }
    }
  );
};


// const tts = await fetch('/api/v1/speech/direct/organizations/org-cx', {
//   headers: {
//     accept: '*/*',
//     'content-type': 'application/json',
//     'sec-fetch-dest': 'empty',
//     'sec-fetch-mode': 'cors',
//     'x-trace-id': '13908545-ac23-4325-81e5-e180f28f410b',
//   },
//   referrer:
//     '//commercial-fab-int-staging.xiaoice.com/organizations/org-cx/webDemos/fb0d5c6d-5942-4de0-a437-34af00e74528',
//   referrerPolicy: 'strict-origin-when-cross-origin',
//   body: '{"message":"数字人平台希望给车企提供一个saas平台，让用户可以在平台上创建自己的虚拟人，完成形象、嘴型驱动、动作表情驱动，并最终可以部署到车机端/其他端，与终端用户进行交互","voice":"zh-CN-YunyangNeural","styleCode":"general","speed":1,"pitch":1}',
//   method: 'POST',
//   mode: 'cors',
//   credentials: 'include',
// });
// let ttsresult = await tts.json();

// let morphTargetDictionary = h.current._characterApi._face.morphTargetDictionary;

// var formdata = new FormData();
// formdata.append('audio', ttsresult.audio);

// var requestOptions = {
//   method: 'POST',
//   body: formdata,
// };

// let face = await fetch('/face_former/predict_arkit', requestOptions);

// let faceresult = await face.json();
// console.log(faceresult, 'faceresult');

// const shapes = (faceresult.blendshapes as number[][]).map((x) => {
//   let y = new Array(66).fill(0);
//   //debugger;
//   for (const key of ARKIT_BS_LIST) {
//     if (ARKIT_BS_DICT[key] && morphTargetDictionary[key] && morphTargetDictionary[key] <= 65) {
//       y[morphTargetDictionary[key]] = x[ARKIT_BS_DICT[key]];
//     }
//   }
//   return y;
// });
// console.log(shapes, 'arkit bs');
// h.current.talkRaw(
//   '数字人平台希望给车企提供一个saas平台，让用户可以在平台上创建自己的虚拟人，完成形象、嘴型驱动、动作表情驱动，并最终可以部署到车机端/其他端，与终端用户进行交互',
//   '',
//   ttsresult.audio,
//   shapes
// );