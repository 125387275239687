export const DESIGN_WIDTH = 2160;

export const DESIGN_HEIGHT = 3840;

export const DESIGN_RATIO = DESIGN_WIDTH / DESIGN_HEIGHT;

export const REAL_RATIO = window.innerWidth / window.innerHeight;

export const ROOT_FONT_SIZE = Math.min(
  (window.innerHeight * DESIGN_WIDTH) / DESIGN_HEIGHT / 10,
  window.innerWidth / 10
);

export const rem = (px: number) => `${(px * 10) / DESIGN_WIDTH}rem`;

export const h = (px: number) =>
  `${(px * window.innerHeight) / DESIGN_HEIGHT}px`;

export const w = (px: number) => `${(px * window.innerWidth) / DESIGN_WIDTH}px`;
