/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'antd/dist/reset.css';
import 'globalthis/auto';
import ReactDOM from 'react-dom/client';
import RootRouter from './route';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
// import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { DESIGN_RATIO, REAL_RATIO, ROOT_FONT_SIZE } from './util';
import './styles/globals.less';
import VConsole from 'vconsole';

// new VConsole();

document.documentElement.style.fontSize = ROOT_FONT_SIZE + 'px';
const $root = document.getElementById('root') as HTMLElement;
if (REAL_RATIO > DESIGN_RATIO) {
  $root.style.width = window.innerHeight * DESIGN_RATIO + 'px';
}

// @ts-ignore
window.RGBELoader = RGBELoader;
const root = ReactDOM.createRoot($root);
root.render(<RootRouter />);


//js bridge rev msg
window.addEventListener('message', (e: MessageEvent) => {
  console.log('message', e.data);
  if (e.data?.type === 'doAction') {
    window.playAction(e.data.action);
  }

  if (e.data?.type === 'stopAction') {
    window.human?.playAction(e.data.action, true);
  }

  if (e.data?.type === 'doTalkText') {
    window.doTalkText(e.data.text,);
  }

  
});