/* eslint-disable no-bitwise */
import * as THREE from 'three';

export function webColorToHex(color: string): number {
  return parseInt(color.replace('#', '0x'), 16);
}

export function rgbaToHex(r: number, g: number, b: number, a: number) {
  r = Math.round(r);
  g = Math.round(g);
  b = Math.round(b);
  a = Math.round(a * 255);
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}${a
    .toString(16)
    .toUpperCase()
    .padStart(2, '0')}`;
}

export function findMorphTarget(
  model: THREE.Object3D,
  result: THREE.Mesh[] = []
): THREE.Mesh[] {
  if ((model as THREE.Mesh).morphTargetDictionary) {
    result.push(model as THREE.Mesh);
  }
  model.children?.forEach((item) => findMorphTarget(item, result));
  return result;
}

export function findMaterialTarget(
  model: THREE.Object3D,
  result: THREE.Mesh[] = []
): THREE.Mesh[] {
  if ((model as THREE.Mesh).material) {
    result.push(model as THREE.Mesh);
  }
  model.children?.forEach((item) => findMaterialTarget(item, result));
  return result;
}


// 从最小值和最大值之间选一个整数:[minVal, maxVal)
export function randIntChoice(minVal: number, maxVal: number) {
  return minVal + Math.floor(Math.random() * (maxVal - minVal));
}

export function activateAction(action: THREE.AnimationAction) {
  action.enabled = true;
  action.setEffectiveTimeScale(1);
  action.setEffectiveWeight(1);
  action.time = 0;
}
