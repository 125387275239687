import { reject } from "lodash-es";
// import { resolve } from "path";

export interface GLBFile {
  url: string;
  data: ArrayBuffer;
}

export default class IDBService {
  private db?: IDBDatabase;

  constructor(private dbName: string, private storeName: string) {}

  async openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as any).result;
        if (!db.objectStoreNames.contains(this.storeName)) {
          db.createObjectStore(this.storeName, { keyPath: 'url' });
        }
      };

      request.onsuccess = () => {
        this.db = request.result;
        resolve(this.db);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async addGLBFile(glbFile: GLBFile): Promise<void> {
    if (!this.db) {
      this.db = await this.openDB();
    }
    console.log('addGLBFile:', glbFile.url);
    const transaction = this.db.transaction([this.storeName], 'readwrite') as IDBTransaction;
    const store = transaction.objectStore(this.storeName);
    const request = store.put(glbFile);
    //await transaction.oncomplete;
    request.onerror = () => reject(request.error);
    request.onsuccess = () => {
      console.log('addGLBFile success');
    };
  }

  async getGLBFile(url: string): Promise<ArrayBuffer | undefined> {
    if (!this.db) {
      this.db = await this.openDB();
    }
    console.log('getGLBFile:', url);
    const transaction = this.db.transaction([this.storeName], 'readonly') as IDBTransaction;
    const store = transaction.objectStore(this.storeName);
    const request = store.get(url);
    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        resolve(request.result?.data);
      };
      request.onerror = () => {
        reject(request.error);
      };
    });
  }

  async deleteGLB(url: string): Promise<void> {
    if (!this.db) {
      this.db = await this.openDB();
    }
    const transaction = this.db.transaction([this.storeName], 'readwrite') as IDBTransaction;
    const store = transaction.objectStore(this.storeName);
    const request = store.delete(url);
    return new Promise((resolve, reject) => {
      request.onerror = () => reject(request.error);
      request.onsuccess = () => {
        console.log('deleteGLB success');
      };
    });
  }
}