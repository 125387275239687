import * as THREE from 'three';
import ee from 'eventemitter2';

export type ActionConfig = {
  loop?: THREE.AnimationActionLoopStyles;
  timeScale?: number;
  weight?: number;
  repetitions?: number;
  clampWhenFinished?: boolean;
  enabled?: boolean;
}

export type WaitType = {
  break?: boolean;
  loop?: boolean;
  finish?: boolean;
}

class Action extends ee {
  action: THREE.AnimationAction;
  name: string;
  mixer: THREE.AnimationMixer;
  breakPromise: Promise<void> | null = null;
  loopPromise: Promise<void> | null = null;
  finishPromise: Promise<void> | null = null;
  breakResolve: ((value: WaitType) => void) | null = null;
  loopResolve: ((value: WaitType) => void) | null = null;
  finishResolve: ((value: WaitType) => void) | null = null;

  constructor(action: THREE.AnimationAction, name: string, config?: ActionConfig) {
    super();
    this.action = action;
    this.action.setLoop(THREE.LoopOnce, 1)
    this.action.clampWhenFinished = true
    // this.action.weight = 0
    this.name = name;
    this.mixer = action.getMixer();
    this.mixer.addEventListener('finished', this.onFinish);
    this.mixer.addEventListener('loop', this.onLoop);
    this.setConfig(config);
  }

  setConfig = (config?: ActionConfig) => {
    if (config) {
      if (config.loop) {
        this.action.setLoop(config.loop, config.repetitions ?? 1)
      }
      if (config.timeScale) {
        this.action.timeScale = config.timeScale
      }
      if (config.clampWhenFinished !== undefined) {
        this.action.clampWhenFinished = config.clampWhenFinished
      }
      if (config.enabled !== undefined) {
        this.action.enabled = config.enabled
      }
      if (config.weight !== undefined) {
        this.action.setEffectiveWeight(config.weight)
      }
    }
  }

  private onFinish = (e: THREE.Event) => {
    if (e.action === this.action) {
      if (this.finishResolve) {
        this.finishResolve?.({finish: true});
        this.finishResolve = null;
      }
      this.breakResolve = null;
      // this.action.weight = 0;
      this.emit('finish');
      console.log('action %s finished', this.name);
      // this.action.stop();
    }
  }

  private onLoop = (e: THREE.Event) => {
    if (e.action === this.action) {
      this.loopResolve?.({loop: true});
      this.loopResolve = null;
      this.emit('loop');
      console.log('action %s looped', this.name);
    }
  }

  waitBreak = (timeout = Infinity): Promise<WaitType> => {
    return new Promise((resolve, reject) => {
      this.breakResolve = resolve;
      if (Number.isFinite(timeout)) {
        setTimeout(() => {
          reject(new Error(`Action ${this.name} timeout of ${timeout}ms exceeded for wait-break`))
          this.breakResolve = null;
        }, timeout)
      }
    })
  }

  break = () => {
    console.log('action %s breaked', this.name);
    if (this.breakResolve) {
      this.breakResolve?.({break: true});
      this.breakResolve = null;
      
      this.emit('break')
    }
    this.loopResolve = null;
    this.finishResolve = null;
    
  }

  waitLoop = (timeout = Infinity): Promise<WaitType> => {
    return new Promise((resolve, reject) => {
      this.loopResolve = resolve;
      if (Number.isFinite(timeout)) {
        setTimeout(() => {
          reject(new Error(`Action ${this.name} timeout of ${timeout}ms exceeded for wait-loop`))
          this.loopResolve = null;
        }, timeout)
      }
    })
  }

  waitFinish = (timeout = Infinity): Promise<WaitType> => {
    return new Promise((resolve, reject) => {
      this.finishResolve = resolve;
      if (Number.isFinite(timeout)) {
        setTimeout(() => {
          reject(new Error(`Action ${this.name} timeout of ${timeout}ms exceeded for wait-finish`))
          this.finishResolve = null;
        }, timeout)
      }
    })
  }

  waitRace = (timeout = Infinity): Promise<WaitType> => {
    return Promise.race([this.waitBreak(timeout), this.waitFinish(timeout), this.waitLoop(timeout)])
  }

  waitFinishOrBreak = (timeout = Infinity): Promise<WaitType> => {
    return Promise.race([this.waitBreak(timeout), this.waitFinish(timeout)])
  }

  crossFadeTo = (action: Action, duration = 0.35, warp = true) =>{
    action.action.enabled = true
    action.action.reset();
    
    if (this.action.isRunning()) {
      this.break();
    }
    this.action.crossFadeTo(action.action, duration, warp);
    action.action.play();
    console.log('crossFadeTo', action.name)
  }

  crossFadeFrom=(action: Action, duration = 0.35, warp = true) =>{
    this.action.enabled = true
    this.action.reset();
    this.action.play();
    this.action.crossFadeFrom(action.action, duration, warp);
    if (action.action.isRunning()) {
      action.break();
    }
    console.log('crossFadeFrom', action.name)
  }

  play = () => {
    this.action.enabled = true;
    this.action.weight = 1; 
    this.action.reset();
    this.action.play();
    console.log('action play', this.name)
  }

  stop = () => {
    this.action.stop();
    this.action.setEffectiveWeight(0) 
    this.action.enabled = false;
    console.log('action stop', this.name)
  }

  get weight(){
    return this.action.getEffectiveWeight();
  }

  get enabled (){
    return this.action.enabled;
  }

}

export default Action;